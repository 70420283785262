import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/header';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import SeoImg from '../images/archive.jpg';
import ArrowTop from '../images/arrow_top_new.png';
import '../style/app.scss';

const CategoryPage = props => {
	const { data } = props;

	return (
		<>
			<Header />
			<section className="single_hero">
				<SEO title="Blog Archive" metaImage={SeoImg} />
				<div className="back_button_wrapper">
					<Link to="/">
						<span>
							<img src={ArrowTop} alt="back arrow" /> Back to all posts
						</span>
					</Link>
				</div>
			</section>
			<section className="blog">
				{data.allWordpressPost.nodes.map((post, i) => (
					<div className="background_image_wrapper" key={post.id}>
						<Img className="blog_image" fluid={post.featured_media.localFile.childImageSharp.fluid} />
						<div className="text_wrapper">
							<h2 dangerouslySetInnerHTML={{ __html: post.title }} />
							<div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
							<Link to={`/${post.slug}`}>Read More</Link>
						</div>
						<div className="post_tags">
							{post.categories.map(tag => (
								<Link to={`/category/${tag.slug}`}>Category of: {tag.name}</Link>
							))}
						</div>
					</div>
				))}
			</section>
			<Footer />
		</>
	);
};

export const pageQuery = graphql`
	query CategoryPage($slug: String!) {
		allWordpressPost(filter: { categories: { elemMatch: { slug: { eq: $slug } } } }) {
			nodes {
				featured_media {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
				categories {
					name
					slug
				}
				date
				excerpt
				slug
				title
				id
			}
		}
	}
`;

export default CategoryPage;
